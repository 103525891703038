<script setup>
import {
  definePageMeta,
  computed,
  defineAsyncComponent,
  reactive,
  ref,
  onUnmounted,
} from '#imports';
import { useModal } from 'vue-final-modal';
import { useMessagePopup } from '@/composables/useMessagePopup';
import { useRestorePasswordStore } from '@/stores/restore-password';
import { formatValues } from '@/utils/helpers';
import { useFormHelp } from '@/composables/useFormHelp';
import { useInfoPopup } from '@/composables/useInfoPopup';
import useGoogleRecaptcha from '@/composables/useGoogleRecaptcha';

import PhoneVerificationForm from '@/components/forms/auth/PhoneVerificationForm.vue';
import PopupCenter from '@/components/layout/popup/PopupCenter.vue';

const { openMessagePopup } = useMessagePopup();
const restorePasswordStore = useRestorePasswordStore();
const formHelpComposable = useFormHelp();
const { openInfoPopup } = useInfoPopup();
const { executeRecaptcha } = useGoogleRecaptcha();

definePageMeta({
  layout: 'empty',
});

const props = defineProps({
  closeModal: {
    type: Function,
    default: undefined,
  },
});

// <editor-fold desc="Steps">
const typesDictionary = {
  phoneVerification: 'phoneVerification',
  phoneConfirmation: 'phoneConfirmation',
  changePassword: 'changePassword',
};
const currentStep = ref(typesDictionary.phoneVerification);
const isTypePhoneConfirmationCall = ref(false);
const phoneNumberForDialing = ref('');

const steps = computed(() => {
  return {
    [typesDictionary.phoneVerification]: {
      component: {
        value: PhoneVerificationForm,
        data: {
          submitForm: sendPhoneVerification,
        },
      },
      steps: {
        index: 1,
        next: {
          value: typesDictionary.phoneConfirmation,
        },
      },
    },
    [typesDictionary.phoneConfirmation]: {
      component: {
        value: defineAsyncComponent(
          () =>
            import('@/components/layout/forms/ConfirmationPhoneWrapper.vue'),
        ),
        data: {
          heading: 'Восстановление пароля',
          infoText: `Для продолжения регистрации, пожалуйста, позвоните с номера телефона <span>${formatValues.phone(formData.mobilePhone)}</span> по номеру, указанному ниже. Звонок бесплатный. Переход произойдет автоматически в течение пяти секунд после звонка`,
          submitForm: sendPhoneConfirmation,
          requestCallAgain,
          isCall: isTypePhoneConfirmationCall.value,
          phoneNumberForDialing: phoneNumberForDialing.value,
          buttonData: {
            text: 'Изменить мой номер',
            action: openConfirmationPhonePopup,
          },
        },
      },
      steps: {
        index: 2,
        next: {
          value: typesDictionary.changePassword,
        },
      },
    },
    [typesDictionary.changePassword]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/layout/forms/PasswordFormWrapper.vue'),
        ),
        data: {
          heading: 'Установка нового пароля',
          description:
            'Пароль должен состоять как минимум из 8 символов, содержать буквы латинского алфавита обоих регистров, цифры или специальные символы',
          submitForm: sendChangePassword,
        },
      },
      steps: {
        index: 3,
        next: {
          value: '',
        },
      },
    },
  };
});
const currentStepData = computed(() => {
  return steps.value[currentStep.value];
});
const currentComponent = computed(() => {
  return currentStepData.value?.component;
});
const currentComponentData = computed(() => {
  return currentComponent.value?.data;
});
const nextStep = computed(() => {
  return currentStepData.value?.steps?.next?.value;
});

function changeStep(step) {
  const isValid = Object.keys(steps.value).includes(step);

  if (!isValid) return;

  currentStep.value = typesDictionary[step];
}
// </editor-fold>

// <editor-fold desc="FORM">
const intervalVerifyCall = ref(null);
const formData = reactive({
  //third step -> phoneVerification
  mobilePhone: '',
  //third step -> changePassword
  password: '',
  password_confirmation: '',
});
function updateForm({ key, value }) {
  formData[key] = value;
}
function checkVerifyCall() {
  if (intervalVerifyCall.value) {
    clearInterval(intervalVerifyCall.value);
    intervalVerifyCall.value = null;
  }

  intervalVerifyCall.value = setInterval(() => {
    restorePasswordStore
      .fetchVerifyCall()
      .then((data) => {
        if (data.status === 'complete') {
          clearInterval(intervalVerifyCall.value);
          intervalVerifyCall.value = null;

          changeStep(nextStep.value);
        }

        if (data.status === 'not_active') {
          clearInterval(intervalVerifyCall.value);
          intervalVerifyCall.value = null;

          changeStep(typesDictionary.phoneVerification);
        }
      })
      .catch(() => {
        clearInterval(intervalVerifyCall.value);
        intervalVerifyCall.value = null;
      });
  }, 5000);
}
async function sendPhoneVerification(values, actions) {
  const { token } = await executeRecaptcha('submit');

  const phoneData = formatValues.phoneOnlyNumbers(formData.mobilePhone);

  restorePasswordStore
    .phoneVerify({ phoneData, token })
    .then((data) => {
      const type = data.type;
      phoneNumberForDialing.value = data.phoneConfirmation;

      if (type === 'beeline') {
        isTypePhoneConfirmationCall.value = true;
        checkVerifyCall();
      }

      changeStep(nextStep.value);
    })
    .catch(({ data }) => {
      formHelpComposable.checkErrors(data, actions.setErrors);
    });
}
async function requestCallAgain() {
  const { token } = await executeRecaptcha('submit');

  const phoneData = formatValues.phoneOnlyNumbers(formData.mobilePhone);

  restorePasswordStore
    .phoneVerify({ phoneData, token })
    .then((data) => {
      const type = data.type;
      phoneNumberForDialing.value = data.phoneConfirmation;

      if (type === 'beeline') {
        isTypePhoneConfirmationCall.value = true;
        checkVerifyCall();
      }
    })
    .catch(({ data }) => {
      const errors = data?.errors || {};
      const errorsName = Object.keys(errors)?.[0];
      const errorMessage = errors?.[errorsName];

      openInfoPopup({
        title: 'Ошибка',
        description: errorMessage,
      });
    });
}
function sendPhoneConfirmation(values, actions) {
  restorePasswordStore
    .codeVerify(formData.code)
    .then(() => {
      changeStep(nextStep.value);
    })
    .catch(({ data }) => {
      formHelpComposable.checkErrors(data, actions.setErrors);
    });
}
async function changePhone(values, actions, closeModal) {
  const { token } = await executeRecaptcha('submit');

  const phoneData = formatValues.phoneOnlyNumbers(values.mobilePhone);

  restorePasswordStore
    .phoneVerify({ phoneData, token })
    .then((data) => {
      const type = data.type;
      formData.mobilePhone = values.mobilePhone;
      phoneNumberForDialing.value = data.phoneConfirmation;
      isTypePhoneConfirmationCall.value = false;

      if (type === 'beeline') {
        isTypePhoneConfirmationCall.value = true;
        checkVerifyCall();
      }

      closeModal?.();
    })
    .catch(({ data }) => {
      formHelpComposable.checkErrors(data, actions.setErrors);
    });
}
function sendChangePassword(values, actions) {
  restorePasswordStore
    .newPassword(formData.password)
    .then(() => {
      openMessagePopup('Пароль успешно изменен');

      props.closeModal?.();
    })
    .catch(({ data }) =>
      formHelpComposable.checkErrors(data, actions.setErrors),
    );
}
function openConfirmationPhonePopup() {
  const { open, close } = useModal({
    modalId: 'confirmationPhonePopup',
    component: defineAsyncComponent(
      () => import('@/components/popups/ChangePhone.vue'),
    ),
    attrs: {
      phoneData: formData.mobilePhone,
      submitForm: changePhone,
      closeModal: () => {
        close();
      },
    },
  });

  open();
}

onUnmounted(() => {
  clearInterval(intervalVerifyCall.value);
});
// </editor-fold>
</script>

<template>
  <PopupCenter full-width-mobile>
    <template #default>
      <div class="restore-password-popup">
        <transition name="fade" mode="out-in">
          <component
            :is="currentComponent.value"
            v-if="currentComponent.value"
            :key="currentStep"
            :form-data="formData"
            :component-data="currentComponentData"
            class="restore-password-popup__component"
            @update-form="updateForm"
          />
        </transition>
      </div>
    </template>
  </PopupCenter>
</template>
<style scoped lang="scss">
.restore-password-popup {
  $parent: &;
}
</style>
